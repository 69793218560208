import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { client, urlFor } from "../../sanityClient";
import { AppWrap, SectionWrap } from "../../wrapper";
import "./About.scss";

const About = () => {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    const query = '*[_type == "abouts"]';
    client
      .fetch(query)
      .then((data) => setSections(data))
      .catch((err) => console.error(`Error fecthing sections - ${err}`));
  }, []);
  return (
    <>
      <h2 className="head-text">
        I know that
        <span> Good Design</span>
        <br />
        means
        <span> Good Business</span>
      </h2>
      <div className="app__profiles">
        {sections.map((section, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: "tween" }}
            className="app__profile-item"
            key={`${section.title}${index}`}
          >
            <img src={urlFor(section.imgUrl)} alt={section.title} />
            <h2 className="bold-text" style={{ marginTOp: 20 }}>
              {section.title}
            </h2>
            <p className="p-text" style={{ marginTOp: 10 }}>
              {section.description}
            </p>
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  SectionWrap(About, "app__about"),
  "about",
  "app__whitebg"
);
